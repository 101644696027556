/* Used for all Modals */

.baseModal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  top: 0%;
  left: 0%;
  z-index: 30;
  position: absolute;
}

.close {
  position: fixed;
  cursor: pointer;
  right: 15px;
  top: 15px;
  background: rgb(255, 255, 255);
  width: 23px;
  height: 23px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: black;
  border: 1px solid #999;
  font-size: 15px;
  z-index: 30;
}

.close:hover {
  box-shadow: rgba(255, 255, 255, 0.25) 0px 54px 55px,
    rgba(255, 255, 255, 0.12) 0px -12px 30px,
    rgba(255, 255, 255, 0.12) 0px 4px 6px,
    rgba(255, 255, 255, 0.17) 0px 12px 13px,
    rgba(255, 255, 255, 0.09) 0px -3px 5px;
}

/* Used for CreateProject.js only */

.frameModal {
  width: 45vw;
  min-width: 300px;
  height: 90vh;
  min-height: 550px;
  background-color: rgb(100, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 0px;
  margin: 0px;
  z-index: 20;
}

.modal {
  width: 35vw;
  min-width: 250px;
  height: 75vh;
  min-height: 525px;
  position: relative;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  overflow: auto;
}

/* Used for LoginModal.js only */

.loginModalFrame {
  width: 28vw;
  min-width: 350px;
  height: 75vh;
  min-height: 550px;
  background-color: rgb(100, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 0px;
  margin: 0px;
  z-index: 20;
}

.loginModal {
  width: 20vw;
  min-width: 250px;
  height: 50vh;
  min-height: 450px;
  background-color: rgb(255, 255, 255);
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

/* Used for InboxModal.js only */
.inboxModal {
  position: fixed;
  bottom: 0.4vw;
  right: 0.4vw;
  width: 30%;
  min-width: 300px;
  height: 30%;
  overflow: auto;
  max-height: 400px;
  background-color: rgb(100, 0, 0);
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.inboxClose {
  position: absolute;
  padding: 0px;
  cursor: pointer;
  width: 23px;
  height: 23px;
  line-height: 20px;
  text-align: center;
  border-radius: 5px 0px 0px 0px;
  color: white;
  font-size: 15px;
  z-index: 20;
}

.inboxClose:hover {
  background: white;
  color: red;
}

/* width */
.modal::-webkit-scrollbar {
  width: 1vw;
  height: 1vw;
  border-radius: 15px;
}

.modal::-webkit-scrollbar-corner {
  background: inherit;
}

/* Track */
.modal::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
.modal::-webkit-scrollbar-thumb {
  background: #999;
  width: 5px;
  height: 5px;
}

/* Handle on hover */
.modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}
