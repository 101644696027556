p {
  text-align: center;
  font-family: "Arial";
  font-size: 12pt;
  margin-top: 3px;
  margin-bottom: 3px;
}

/* Used for user login */

.loginBody {
  height: 100%;
  display: flex;
  padding: 0px 25px;
  flex-direction: column;
  justify-content: center;
}

.loginBody h1 {
  text-align: center;
  font-family: "Arial";
  font-size: 34pt;
  margin: 20px;
}

.loginBody span {
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-family: "Arial";
  color: rgb(6, 69, 173);
  font-size: 12pt;
  margin-top: 3px;
  margin-bottom: 3px;
}

.loginBody span:hover {
  color: rgb(11, 0, 128);
}

.loginBody input {
  width: 100%;
  padding: 12px 20px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.loginBody button {
  width: 100%;
  background-color: rgb(100, 0, 0);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.loginBody button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

/* Used for create user */

.createUserBody {
  height: 100%;
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
}

.createUserBody .createUserInput {
  width: 100%;
  height: 30px;
  padding: 6px 10px;
  margin: 2px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: max(0.8vw, 0.6rem);
}

.createUserBody .createButton {
  width: 50%;
  height: 30px;
  background-color: rgb(100, 0, 0);
  color: white;
  padding: 6px 10px;
  margin: 10px 0px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: max(0.8vw, 0.6rem);
}

.createUserBody .tag {
  font-size: max(1vw, 0.5rem);
  color: black;
  box-sizing: border-box;
  border: 2px solid #888;
  padding: 5px;
  margin: 1px;
}

.createUserBody #selected {
  box-sizing: border-box;
  border: 2px solid black;
  margin: 1px;
}

.createUserBody .box {
  margin: 4px 0;
  padding: 5px 5px;
  border: 2px solid black;
  border-radius: 4px;
  justify-content: center;
}
