body {
  height: auto;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}
html {
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  overflow-x: hidden;
}

[theme="HC"] {
  --first-color: white;
  --second-color: #050;
  --third-color: #005;
  --fourth-color: #500;
  --fifth-color: #ccc;
  --sixth-color: white;
  --font-color: black;
  --secondary-font: black;
}

[theme="default"] {
  --first-color: #888;
  --second-color: #333;
  --third-color: #555;
  --fourth-color: #500;
  --fifth-color: #aaa;
  --sixth-color: white;
  --font-color: black;
  --secondary-font: white;
}
