.base {
  width: 100vw;
  height: max(auto, 90vh);
  background-color: var(--first-color);
  padding: none;
  margin: none;
  overflow-x: hidden;

  position: relative;
  z-index: 10;
}

.base1 {
  width: 100vw;
  min-height: 90vh;
  height: auto;
  background-color: var(--first-color);
  padding: none;
  margin: none;
  overflow-x: hidden;

  position: relative;
}

.base2 {
  width: 100vw;
  min-height: 90vh;
  height: auto;
  background-color: var(--first-color);
  padding: none;
  margin: none;
  overflow-x: hidden;

  position: relative;
}

#backdrop {
  width: 90vw;
  height: min(90vh, 100vw);
  margin: 5vw;
  background-color: var(--fifth-color);
  color: var(--font-color);
  opacity: 1;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  font-size: min(2rem, 4vw);
  font-family: "Arial";
}

#image-logo {
  width: min(90%, 700px);
  height: auto;
  margin-top: 7.5%;
  margin-bottom: 7%;
  border: 2px solid black;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-image: cover;
}

#blurb {
  margin-left: 50px;
  margin-right: 50px;
}
