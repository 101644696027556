.iconbutton {
  width: 4rem;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: white;
  padding: 0.5rem;
  border: none;
  justify-content: center;
  align-items: center;
  opacity: 1;
  font-size: max(2vw, 20px);
  margin-right: 0.2rem;
}

.tag {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: white;
  border: none;
  justify-content: center;
  align-items: center;
  opacity: 1;
  padding: 0.5rem;
  border-radius: 5px;
}

#set {
  background-color: #300;
}

.iconbuttonc {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: white;
  padding: 0.5rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  border: none;
  justify-content: center;
  align-items: center;
  opacity: 0;

  font-size: max(2vw, 15px);
}

.dropdownIcon {
  background: "transparent" !important;
  box-shadow: "none";
  border: "0px";
  font-size: "max(2vw, 15px)";
  padding: "8px";
  margin-left: "0.3rem";
  margin-right: "0.3rem";
}

.iconbuttonr {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: white;
  padding: 0.5rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  border: none;
  justify-content: center;
  align-items: center;

  font-size: max(2vw, 15px);
}

.searchinput {
  width: 100%;
  height: min(4vw, 1.5rem);
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid white;
  border-radius: none;
  outline: none;
  padding-left: 2%;
  color: white;
  font-size: min(2vw, 1.5rem);
  font-family: "Arial";
}

.searchinput::placeholder {
  /* Chrome/Opera/Safari */
  color: white;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
}

.iconbutton:hover {
  cursor: pointer;
  background-color: #555;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.dropdownIcon:hover {
  cursor: pointer;
  background-color: #555;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.tag:hover {
  cursor: pointer;
  box-sizing: content-box;
  box-shadow: inset 0 0 0 2px black;
}

.iconbuttonr:hover {
  cursor: pointer;
  background-color: #555;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.iconbuttonc:hover {
  cursor: pointer;
  background-color: #555;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 5px;
  grid-gap: 3rem;
  background-color: transparent;
  padding-left: 5%;

  overflow-x: scroll;
}

@media only screen and (max-width: 1400px) {
  .grid {
    grid-gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .grid {
    grid-gap: 0.7rem;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .grid {
    grid-gap: 0.5rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .tag {
    padding: 2px;
  }
}

@media only screen and (max-width: 600px) {
  .grid {
    grid-gap: 0.3rem;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.2rem;
  }
  .tag {
    padding: 2px;
    font-size: 0.7rem;
  }
}

/* width */
.grid::-webkit-scrollbar {
  width: 5px;
  height: 1vw;
}

.grid::-webkit-scrollbar-corner {
  background: inherit;
}

/* Track */
.grid::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
.grid::-webkit-scrollbar-thumb {
  background: #999;
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

/* Handle on hover */
.grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}
