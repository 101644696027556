.carousel {
  width: 90vw;
  height: max(15vw, 150px);
  display: flex;
  margin: 2.5vw;
  flex-direction: row;
  align-items: center;
  padding-left: 5%;
  justify-content: left;

  background-color: var(--first-color);
}

.carouselName {
  width: 30vw;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
  margin-top: 1rem;
  font-size: min(2rem, 4vw);
  border-bottom: 3px solid black;
  font-family: "Arial";

  background-color: transparent;
  color: var(--secondary-font);
}

.right {
  background-color: transparent;
  color: #333;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3rem;

  font-size: 2vw;
}

.right1 {
  background-color: transparent;
  color: #333;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3rem;

  font-size: 2vw;
}

.left {
  background-color: transparent;
  color: #333;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3rem;

  font-size: 2vw;
}

.left:hover {
  transition: 0.5s;
  background-color: #ddd;
  cursor: pointer;
}

.left1 {
  background-color: transparent;
  color: #333;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3rem;

  font-size: 2vw;
}

.right:hover {
  transition: 0.5s;
  background-color: #ddd;
  cursor: pointer;
}

#empty {
  width: auto;
  height: 5rem;
  font-size: min(7rem, 14vw);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  border-radius: 3px;

  background-color: var(--fifth-color);

  font-size: min(2rem, 4vw);
  font-family: "Arial";
  color: var(--font-color);
}
