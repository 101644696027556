.projecttab {
  width: 15vw;
  height: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  color: #222;
  margin-right: 0.5rem;
  margin-left: 0.5rem;

  font-family: "Arial";
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  transition: 0.5s;
}

.projecttab:hover {
  transform: scale(1.1);
  cursor: pointer;
}

#slidein {
  animation-name: showtab;
  animation-duration: 0.21s;
  animation-timing-function: ease-in-out;
}

#slideout {
  animation-name: hidetab;
  animation-duration: 0.21s;
  animation-timing-function: ease-in-out;
}

@keyframes showtab {
  0% {
    width: 0px;
    height: 0px;
    color: transparent;
    background-color: transparent;
  }
  95% {
    color: transparent;
    background-color: transparent;
  }
  100% {
  }
}
@keyframes hidetab {
  from {
    opacity: 0;
    color: transparent;
    background-color: transparent;
  }
  to {
    width: 0px;
    height: 0px;
    color: transparent;
    background-color: transparent;
    font-size: 0px;
  }
}

.projectimage {
  width: inherit;
  height: 9vw;
  background-color: #500;

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.projectmessage {
  width: inherit;
  height: 6vw;
  background-color: #f8f8ff;

  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

h4 {
  font-size: 1.5vw;
}
