.projectViewerBody {
  height: 100%;
  width: 100%;
}

.projectViewerBody h1 {
  font-size: max(1.8vw, 1.2rem);
  margin: 0px;
  padding: 1vw;
  padding-left: 0px;
  font-family: "Arial";
  font-weight: 600;

  text-decoration: underline;
}

.projectViewerBody .titleField {
  width: 20vw;
  min-width: 200px;
  padding: 6px;
  margin: 0px;
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: max(1.8vw, 1.2rem);
  text-align: center;
  font-family: "Arial";
  font-weight: 600;
  text-decoration: underline;
}

.projectViewerBody .descriptionField {
  width: 100%;
  min-width: 200px;
  height: 50%;
  resize: none;
  padding: 6px;
  margin: 0px;
  align-self: center;
  display: inline-block;
  border-radius: 4px;
  font-size: max(1vw, 0.5rem);
  box-sizing: border-box;
  font-family: "Arial";
}

.projectViewerBody .membersField {
  width: 100%;
  min-width: 200px;
  height: 30%;
  resize: none;
  overflow: hidden;
  padding: 6px;
  align-self: center;
  display: inline-block;
  border-radius: 4px;
  font-size: max(1vw, 0.5rem);
  box-sizing: border-box;
  font-family: "Arial";
}

.projectViewerBody .progressField {
  width: 40px;
  height: 25px;
  resize: none;
  overflow: hidden;
  padding: 6px;
  align-self: center;
  display: block;
  border-radius: 4px;
  font-size: max(1vw, 0.8rem);
  text-align: center;
  box-sizing: border-box;
  font-family: "Arial";
}

.projectViewerBody .tag {
  color: black;
  padding: 4px;
  font-size: max(1vw, 0.5rem);
  font-family: "Arial";
}

.projectViewerBody .tag1 {
  color: black;
  padding: 4px;
  font-size: max(1vw, 0.5rem);
  font-family: "Arial";
}

.projectViewerBody .joinDescription {
  width: 100%;
  min-width: 200px;
  height: 50%;
  resize: none;
  padding: 6px;
  margin: 10px 0px;
  align-self: center;
  display: inline-block;
  border-radius: 4px;
  font-size: max(1vw, 0.5rem);
  box-sizing: border-box;
  font-family: "Arial";
}

.projectViewerBody .box {
  border: 2px solid black;
  padding: 4px;
  border-radius: 5px;
}

.projectViewerBody .tag1:hover {
}

#selected {
  background-color: rgb(100, 0, 0);
  color: white;
  border: 1px solid white;
}

.createButton {
  height: 2rem;
  width: 5rem;
  background-color: rgb(100, 0, 0);
  color: white;
  border: none;
  border-radius: 4px;
  margin: 20px 10px;
}

.createButton:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  cursor: pointer;
}

/* Progress Bar */

.progressBar {
  margin: 10px auto;
  padding: 0px;
  width: 100%;
  background: white;
  border: 1px solid black;
  border-radius: 0px;
  height: 20px;
  overflow: hidden;
  display: block;
}

.progressBar .progress {
  height: 100%;
  width: 0;
  padding: 0px;
  border-radius: 0px;
  background: rgb(100, 0, 0);
  transition: 0.4s;
}

/* bootstrap stuff */

.row {
  padding-left: 5px;
  padding-right: 5px;
}
